/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint no-nested-ternary: "off" */
/* eslint-disable no-unused-vars */

/*
  TODO: Q5. Failed Courses:
  A few of the faculty found that after they had entered in a failed course
  (or multiple failed courses), the names of the courses weren't displaying properly.
  For example, take a look at Enoch Oduro's file (assigned to Kboyd@llu.edu) -
  there should be a fifth course PSYC 501 where it says "remove" but the title isn't showing up.
  Another example is in Chrysan Hoyt's file (Advisor: PFlynn@llu.edu).

  Also, would it be possible to pre-populate this section with failed courses that were
  entered into the student's ASE from the previous year?

TODO: We'll also need to be able to change a student's faculty advisor if they switch labs.
*/

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Progress,
  Alert,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Table,
  Label,
  Input,
} from 'reactstrap';
import { format } from 'date-fns';
import axios from 'axios';
import auth0Client from '../../../Auth/Auth';

import Widget from '../../Widget/Widget';

import '../forms.scss';
import './ASE.scss';

import loadingSpinner from '../../../images/Eclipse-1s-200px.svg';

class ASE extends React.Component {
  constructor(props) {
    super(props);
    this.getForm = this.getForm.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.onEditFrmBtnClick = this.onEditFrmBtnClick.bind(this);
    this.onFrmBtnClick = this.onFrmBtnClick.bind(this);
    this.onFrmBtnToggle = this.onFrmBtnToggle.bind(this);
    this.onAddFailedCourseBtnClick = this.onAddFailedCourseBtnClick.bind(this);
    this.onRemoveFailedCourseBtnClick = this.onRemoveFailedCourseBtnClick.bind(this);
    this.onSaveFailedCourseBtnClick = this.onSaveFailedCourseBtnClick.bind(this);
    this.onCancelFailedCourseBtnClick = this.onCancelFailedCourseBtnClick.bind(this);
    this.onFailedCourseQuestionBtnClick = this.onFailedCourseQuestionBtnClick.bind(this);
    this.onSectionBtnClick = this.onSectionBtnClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validateGPA = this.validateGPA.bind(this);

    this.state = {
      formData: [
        {
          id: 'Academic Standing',
          data: [
            {
              id: 'CurrentStanding',
              value: '',
            },
            {
              id: 'AcademicStatus',
              value: '',
            },
            {
              id: 'DateLeftProgram',
              value: '',
            },
            {
              id: 'ExpectedReturnDate',
              value: '',
            },
            {
              id: 'ReasonLeftProgram',
              value: '',
            },
            {
              id: 'OtherReasonLeftProgram',
              value: '',
            },
            {
              id: 'TuitionStatus',
              value: '',
            },
            {
              id: 'GPA',
              value: '',
            },
            {
              id: 'DidStudentFailAnyCourses',
              value: '',
            },
            {
              id: 'FailedCourses',
              value: [],
            },
            {
              id: 'GoodStanding',
              value: -1,
            },
            {
              id: 'AcademicWarning',
              value: -1,
            },
            {
              id: 'ClinicalWarning',
              value: -1,
            },
            {
              id: 'AcademicProbation',
              value: -1,
            },
            {
              id: 'ClinicalProbation',
              value: -1,
            },
          ],
        },
        {
          id: 'Student Ratings',
          data: [
            {
              id: 'q6',
              value: '',
            },
            {
              id: 'q7',
              value: '',
            },
            {
              id: 'q8',
              value: '',
            },
            {
              id: 'q9',
              value: '',
            },
            {
              id: 'q10',
              value: '',
            },
            {
              id: 'q11',
              value: '',
            },
            {
              id: 'q12',
              value: '',
            },
            {
              id: 'q13',
              value: '',
            },
          ],
        },
        {
          id: 'Student Rating Comments',
          data: [
            {
              id: 'AcademicComments',
              value: '',
            },
            {
              id: 'ResearchComments',
              value: '',
            },
            {
              id: 'ClinicalComments',
              value: '',
            },
            {
              id: 'ProfessionalismComments',
              value: '',
            },
            {
              id: 'ProgramExtensionComments',
              value: '',
            },
          ],
        },
      ],
      expectedReturnDate: format(new Date(), 'yyyy-MM-dd'),
      dateLeftProgram: format(new Date(), 'yyyy-MM-dd'),
      reasonLeftProgramSelect: '',
      otherReason: '',
      GPA: '0.00',
      gpaError: false,
      gpaErrorMsg: '',
      academicComments: '',
      researchComments: '',
      clinicalComments: '',
      professionalismComments: '',
      programExtensionComments: '',
      failedCourses: [],
      addNewFailedCourse: false,
      failedYearSelect: '',
      failedAcademicTerm: '',
      failedCourseSelect: '',
      failedGradeSelect: '',
      failedCourseRetaken: '',
      retakenYearSelect: '',
      retakenAcademicTerm: '',
      retakenGradeSelect: '',
      status: '',
      studentID: '',
      studentName: '',
      currentProgram: '',
      year: '',
      currentSection: 0,
      loading: true,
    };
  }

  componentDidMount() {
    this.getForm();
  }

  handleInputChange(e) {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  onEditFrmBtnClick() {
    this.setState({
      currentSection: 0,
    });
  }

  onFrmBtnClick(sectionID, questionID, a, sA) {
    /* a === answer w/ 3 args, subID w/ 4 args
      sA === answer w/ 4 args */

    // console.log(arguments.length);

    const newState = {
      ...this.state.formData,
    };

    if (arguments.length === 3) {
      newState[sectionID].data[questionID].value === a
        ? (newState[sectionID].data[questionID].value = '')
        : (newState[sectionID].data[questionID].value = a);
    } else if (arguments.length === 4) {
      newState[sectionID].data[questionID].data[a].value === sA
        ? (newState[sectionID].data[questionID].data[a].value = '')
        : (newState[sectionID].data[questionID].data[a].value = sA);
    }

    this.setState({
      formData: newState,
    });
  }

  onFrmBtnToggle(sectionID, questionID) {
    const newState = {
      ...this.state.formData,
    };

    newState[sectionID].data[questionID].value *= -1;

    this.setState({
      formData: newState,
    });
  }

  onSectionBtnClick(e, sectionValue) {
    e.preventDefault();
    e.target.blur();
    window.scrollTo(0, 0);

    const { currentSection } = this.state;
    const newSectionID = currentSection + sectionValue;

    this.setState({
      currentSection: newSectionID,
    });
  }

  onAddFailedCourseBtnClick(e) {
    e.preventDefault();
    e.target.blur();

    this.setState({
      addNewFailedCourse: true,
    });
  }

  onRemoveFailedCourseBtnClick(e) {
    e.preventDefault();
    e.target.blur();

    const { failedCourses } = this.state;

    failedCourses.splice(e.target.dataset.id, 1);

    this.setState({ failedCourses });
  }

  onFailedCourseQuestionBtnClick(e) {
    e.preventDefault();
    e.target.blur();

    // console.log(`Clicked ${e.target.dataset.id}, Setting Value to ${e.target.dataset.value}`);

    this.setState({
      [e.target.dataset.id]: e.target.dataset.value,
    });
  }

  onSaveFailedCourseBtnClick(e) {
    e.preventDefault();
    e.target.blur();

    const { failedCourses, failedAcademicTerm, failedCourseRetaken, retakenAcademicTerm } =
      this.state;

    let {
      failedYearSelect,
      failedCourseSelect,
      failedGradeSelect,
      retakenYearSelect,
      retakenGradeSelect,
    } = this.state;

    // assign default value if field is empty
    if (failedYearSelect === '') {
      failedYearSelect = '2022';
    }
    if (failedCourseSelect === '') {
      failedCourseSelect = 'PSYC 501 Advanced Statistics I';
    }
    if (failedGradeSelect === '') {
      failedGradeSelect = 'B-';
    }
    if (failedCourseRetaken === 'Yes') {
      if (retakenYearSelect === '') {
        retakenYearSelect = '2022';
      }
      if (retakenGradeSelect === '') {
        retakenGradeSelect = 'A';
      }
    }

    failedCourses.push({
      FailedYear: failedYearSelect,
      FailedTerm: failedAcademicTerm,
      FailedCourse: failedCourseSelect,
      FailedGrade: failedGradeSelect,
      Retaken: failedCourseRetaken,
      RetakenYear: retakenYearSelect,
      RetakenTerm: retakenAcademicTerm,
      RetakenGrade: retakenGradeSelect,
    });

    this.setState({
      failedCourses,
      addNewFailedCourse: false,
      failedYearSelect: '',
      failedAcademicTerm: '',
      failedCourseSelect: '',
      failedGradeSelect: '',
      failedCourseRetaken: '',
      retakenYearSelect: '',
      retakenAcademicTerm: '',
      retakenGradeSelect: '',
    });
  }

  onCancelFailedCourseBtnClick(e) {
    e.preventDefault();
    e.target.blur();

    this.setState({
      addNewFailedCourse: false,
      failedYearSelect: '',
      failedAcademicTerm: '',
      failedCourseSelect: '',
      failedGradeSelect: '',
      failedCourseRetaken: '',
      retakenYearSelect: '',
      retakenAcademicTerm: '',
      retakenGradeSelect: '',
    });
  }

  getForm() {
    const {
      match: { params },
    } = this.props;
    // const url = `https://llu-api.modemtones.com/form/${params.formid}`;
    const url = `${process.env.REACT_APP_API_URL}/form/${params.formid}`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        // check if data is valid
        const { data, status, student } = response.data;
        const { currentProgram, year } = student;
        const { _id } = student;
        const studentName = `${student.firstName} ${student.lastName}`;

        // console.log(`Got Form! Status is: ${status}`);
        // console.log(response.data);
        // console.log(data);
        // console.log(currentProgram);
        // console.log(year);

        if (status !== 'new') {
          let {
            formData,
            expectedReturnDate,
            dateLeftProgram,
            reasonLeftProgramSelect,
            otherReason,
            GPA,
            academicComments,
            researchComments,
            clinicalComments,
            professionalismComments,
            programExtensionComments,
            failedCourses,
            currentSection,
          } = this.state;

          // check if data was returned in a array
          if (data.length === 3) {
            formData = data;
          } else {
            formData = data[0];
          }

          // check academic status
          if (formData[0].data[1].value === 'Left Program') {
            // student left program, set dateLeftProgram
            dateLeftProgram = format(new Date(formData[0].data[2].value), 'yyyy-MM-dd');
            // set reasonLeftProgram
            reasonLeftProgramSelect = formData[0].data[4].value;
            if (reasonLeftProgramSelect === 'Other reasons') {
              // other reasons selected, set otherReason
              otherReason = formData[0].data[5].value;
            }
          } else if (formData[0].data[1].value === 'Leave of Absence') {
            // student took leave of absence , set expectedReturnDate
            expectedReturnDate = format(new Date(formData[0].data[3].value), 'yyyy-MM-dd');
            // set reasonLeftProgram
            reasonLeftProgramSelect = formData[0].data[4].value;
            if (reasonLeftProgramSelect === 'Other reasons') {
              // other reasons selected, set otherReason
              otherReason = formData[0].data[5].value;
            }
          } else if (formData[0].data[1].value === 'Other') {
            // set otherReason
            otherReason = formData[0].data[5].value;
          }

          // set GPA
          GPA = formData[0].data[7].value;

          // check if student failed any courses
          if (formData[0].data[8].value === 'Yes') {
            // set failedCourses
            failedCourses = formData[0].data[9].value;

            // check if status is complete
            if (status === 'completed') {
              let fcIdx;
              for (fcIdx = 0; fcIdx < failedCourses.length; fcIdx++) {
                // if FailedYear is blank, display the default course year
                if (failedCourses[fcIdx].FailedYear === '') {
                  failedCourses[fcIdx].FailedYear = '2022';
                }
                // if FailedCourse is blank, display the default course name
                if (failedCourses[fcIdx].FailedCourse === '') {
                  failedCourses[fcIdx].FailedCourse = 'PSYC 501 Advanced Statistics I';
                }
                // if FailedGrade is blank, display the default grade
                if (failedCourses[fcIdx].FailedGrade === '') {
                  failedCourses[fcIdx].FailedCourse = 'B-';
                }
                // set retakenYear, retakenTerm & retakenGrade to N/A if course was not retaken
                if (failedCourses[fcIdx].Retaken === 'No') {
                  failedCourses[fcIdx].RetakenGrade = 'N/A';
                  failedCourses[fcIdx].RetakenYear = 'N/A';
                  failedCourses[fcIdx].RetakenTerm = 'N/A';
                } else {
                  if (failedCourses[fcIdx].RetakenGrade === '') {
                    // if RetakenGrade is blank, display the default grade
                    failedCourses[fcIdx].RetakenGrade = 'A';
                  }
                  if (failedCourses[fcIdx].RetakenYear === '') {
                    // if RetakenYear is blank, display the default grade
                    failedCourses[fcIdx].RetakenYear = '2022';
                  }
                }
              }
            }
          }

          // set comments
          academicComments = formData[2].data[0].value;
          researchComments = formData[2].data[1].value;
          clinicalComments = formData[2].data[2].value;
          professionalismComments = formData[2].data[3].value;
          // we need this hack because question was added after some forms were already complete
          // check if the property exists, if not push it to the array
          if (formData[2].data.length === 5) {
            programExtensionComments = formData[2].data[4].value;
          } else {
            formData[2].data.push({
              id: 'ProgramExtensionComments',
              value: '',
            });
            programExtensionComments = '';
          }

          // if form is completed check comments and set to 'No Comments' if empty
          if (status === 'completed') {
            if (academicComments === '') {
              academicComments = 'No Comments';
            }
            if (researchComments === '') {
              researchComments = 'No Comments';
            }
            if (clinicalComments === '') {
              clinicalComments = 'No Comments';
            }
            if (professionalismComments === '') {
              professionalismComments = 'No Comments';
            }
            if (programExtensionComments === '') {
              programExtensionComments = 'No Comments';
            }
          }

          // if form is cmmpleted, set currentSection to display readout
          if (status === 'completed') {
            currentSection = 999;
          }

          this.setState({
            formData,
            expectedReturnDate,
            dateLeftProgram,
            reasonLeftProgramSelect,
            otherReason,
            GPA,
            academicComments,
            researchComments,
            clinicalComments,
            professionalismComments,
            programExtensionComments,
            failedCourses,
            status,
            studentID: _id,
            studentName,
            currentProgram,
            year,
            currentSection,
            loading: false,
          });
        } else {
          this.setState({
            status,
            studentName,
            studentID: _id,
            currentProgram,
            year,
            loading: false,
          });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }

  saveForm(e, newStatus) {
    e.preventDefault();
    e.target.blur();
    window.scrollTo(0, 0);

    // console.log('***Inside saveForm***');

    const {
      match: { params },
    } = this.props;
    // const url = 'https://llu-api.modemtones.com/form/update';
    const url = `${process.env.REACT_APP_API_URL}/form/update`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const id = params.formid;
    const {
      formData,
      expectedReturnDate,
      dateLeftProgram,
      reasonLeftProgramSelect,
      otherReason,
      GPA,
      academicComments,
      researchComments,
      clinicalComments,
      professionalismComments,
      programExtensionComments,
      failedCourses,
    } = this.state;

    // check academic status
    if (formData[0].data[1].value === 'Left Program') {
      // student left program, set dateLeftProgram
      const [year, month, day] = dateLeftProgram.split('-');
      formData[0].data[2].value = new Date(year, month - 1, day);
      // set reasonLeftProgram
      formData[0].data[4].value = reasonLeftProgramSelect;
      if (reasonLeftProgramSelect === 'Other reasons') {
        // other reasons selected, set otherReason
        formData[0].data[5].value = otherReason;
      }
    } else if (formData[0].data[1].value === 'Leave of Absence') {
      // student took leave of absence , set expectedReturnDate
      const [year, month, day] = expectedReturnDate.split('-');
      formData[0].data[3].value = new Date(year, month - 1, day);
      // set reasonLeftProgram
      formData[0].data[4].value = reasonLeftProgramSelect;
      if (reasonLeftProgramSelect === 'Other reasons') {
        // other reasons selected, set otherReason
        formData[0].data[5].value = otherReason;
      }
    } else if (formData[0].data[1].value === 'Other') {
      // set otherReason
      formData[0].data[5].value = otherReason;
    }

    // set GPA
    formData[0].data[7].value = GPA;

    // check if student failed any courses
    if (formData[0].data[8].value === 'Yes') {
      // set failedCourses
      formData[0].data[9].value = failedCourses;
    }

    // set comments
    formData[2].data[0].value = academicComments;
    formData[2].data[1].value = researchComments;
    formData[2].data[2].value = clinicalComments;
    formData[2].data[3].value = professionalismComments;
    formData[2].data[4].value = programExtensionComments;

    axios
      .post(url, { id, newStatus, formData }, config)
      .then((response) => {
        // update form status if results are valid
        const { status } = response.data;
        this.setState({ status });
        if (status === 'completed') {
          this.props.history.push('/dashboard');
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }

  validateGPA() {
    let gpaError = false;
    let gpaErrorMsg = '';
    let { GPA } = this.state;
    const legalValue = /^[0-4][.][0-9][0-9]$/;

    if (GPA.length === 3) {
      GPA = `${GPA}0`;
    }

    if (GPA === '') {
      gpaError = true;
      gpaErrorMsg = 'GPA cannot be blank.';
    } else if (!legalValue.test(GPA)) {
      gpaError = true;
      gpaErrorMsg = 'Please enter a valid 3 digit GPA';
    }

    this.setState({
      gpaError,
      gpaErrorMsg,
      GPA,
    });
  }

  renderAddFailedCourse() {
    const {
      failedYearSelect,
      failedAcademicTerm,
      failedCourseSelect,
      failedGradeSelect,
      failedCourseRetaken,
      retakenYearSelect,
      retakenAcademicTerm,
      retakenGradeSelect,
    } = this.state;

    return (
      <div className="submitBlock">
        <Row>
          <Col className="text-center">
            <p>We need to look into this, please provide more details.</p>
            <p>
              <br />
            </p>
          </Col>
        </Row>
        {/* failedYearSelect */}
        <Row>
          <Col sm={2} />
          <Col sm={8} className="text-center">
            <Label for="failedYearSelect">
              <i className="fa fa-chevron-right" /> Year
            </Label>
            <Input
              type="select"
              id="failedYearSelect"
              name="failedYearSelect"
              value={failedYearSelect}
              onChange={this.handleInputChange}
            >
              <option value="2023">2023-2024</option>
              <option value="2022">2022-2023</option>
              <option value="2021">2021-2022</option>
              <option value="2020">2020-2021</option>
              <option value="2019">2019-2020</option>
              <option value="2018">2018-2019</option>
            </Input>
            <p>
              <br />
            </p>
          </Col>
          <Col sm={2} />
        </Row>
        {/* failedYearSelect */}
        {/* failedAcademicTerm */}
        <Row>
          <Col className="text-center">
            <Label>
              <i className="fa fa-chevron-right" /> Academic term
            </Label>
            <br />
            <Button
              className="btn-form"
              id="failedAcademicTerm"
              data-id="failedAcademicTerm"
              data-value="Summer"
              color={failedAcademicTerm === 'Summer' ? 'dark' : 'inverse'}
              onClick={this.onFailedCourseQuestionBtnClick}
              active={failedAcademicTerm === 'Summer'}
            >
              Summer
            </Button>
            <Button
              className="btn-form"
              id="failedAcademicTerm"
              data-id="failedAcademicTerm"
              data-value="Fall"
              color={failedAcademicTerm === 'Fall' ? 'dark' : 'inverse'}
              onClick={this.onFailedCourseQuestionBtnClick}
              active={failedAcademicTerm === 'Fall'}
            >
              Fall
            </Button>
            <Button
              className="btn-form"
              id="failedAcademicTerm"
              data-id="failedAcademicTerm"
              data-value="Winter"
              color={failedAcademicTerm === 'Winter' ? 'dark' : 'inverse'}
              onClick={this.onFailedCourseQuestionBtnClick}
              active={failedAcademicTerm === 'Winter'}
            >
              Winter
            </Button>
            <Button
              className="btn-form"
              id="failedAcademicTerm"
              data-id="failedAcademicTerm"
              data-value="Spring"
              color={failedAcademicTerm === 'Spring' ? 'dark' : 'inverse'}
              onClick={this.onFailedCourseQuestionBtnClick}
              active={failedAcademicTerm === 'Spring'}
            >
              Spring
            </Button>
            <p>
              <br />
            </p>
          </Col>
        </Row>
        {/* // failedAcademicTerm */}
        {/* failedCourseSelect */}
        <Row>
          <Col sm={2} />
          <Col sm={8} className="text-center">
            <Label for="failedCourseSelect">
              <i className="fa fa-chevron-right" /> Course
            </Label>
            <Input
              type="select"
              id="failedCourseSelect"
              name="failedCourseSelect"
              value={failedCourseSelect}
              onChange={this.handleInputChange}
            >
              <option>PSYC 501 Advanced Statistics I</option>
              <option>PSYC 502 Advanced Statistics II</option>
              <option>PSYC 503 Advanced Statistics III</option>
              <option>PSYC 505 Research Methods in Psychological Sciences</option>
              <option>PSYC 511 Psychometric Foundations</option>
              <option>PSYC 512/L Cognitive/Intellectual Assessment</option>
              <option>PSYC 513/L Objective Personality Assessment</option>
              <option>PSYC 516/L Neuropsychological Assessment</option>
              <option>PSYC 524 History, Systems & Philosophy of Psychology</option>
              <option>PSYC 526 Ethics & Legal Issues in Psychology</option>
              <option>PSYC 537 Applied Behavior Medicine</option>
              <option>PSYC 545 Cognitive Foundations</option>
              <option>PSYC 546 Clinical Psych. and Practice in Medical Settings</option>
              <option>PSYC 547 Health Psychology Assessment</option>
              <option>PSYC 551 Psychobiological Foundations</option>
              <option>PSYC 553 Cognitive Neuroscience</option>
              <option>PSYC 554 Health Psychology</option>
              <option>PSYC 555 Psychopharmacology</option>
              <option>PSYC 564 Foundations of Social Psychology & Culture</option>
              <option>PSYC 566 Cultural Psychology</option>
              <option>PSYC 567 Human Diversity</option>
              <option>PSYC 571 Adult Psychopathology</option>
              <option>PSYC 572 Child Psychopathology</option>
              <option>PSYC 575 Foundations of Developmental Psychology</option>
              <option>PSYC 581/L Evidence Based Psychological Practice I</option>
              <option>PSYC 582/L Evidence Based Psychological Practice II</option>
              <option>PSYC 583/L Evidence Based Psychological Practice III</option>
              <option>PSYC 584/L Evidence Based Psychological Practice IV</option>
              <option>PSYC 591 Colloquium</option>
              <option>PSYC 597 Supervised Research: Lab Affiliation</option>
              <option>PSYC 604 Seminar in Statistics</option>
              <option>PSYC 654 Behavioral Neurology</option>
              <option>PSYC 646 The Nature of Emotion</option>
              <option>PSYC 676 Geropsychology (CA Licensure)</option>
              <option>PSYC 681 Clinical Supervision and Consultation</option>
              <option>PSYC 681L Clinical Supervision and Consultation Lab</option>
              <option>PSYC 683 Management and Professional Practice</option>
              <option>PSYC 684 Human Sexuality (CA Licensure)</option>
              <option>PSYC 685 Drug Addiction/Treatment (CA Licensure)</option>
              <option>PSYC 686 Elder, Partner, Child Abuse (CA Licensure)</option>
              <option>PSYC 694 Advanced Topics</option>
              <option>PSYC 696 Psy.D. Doctoral Research</option>
              <option>PSYC 697 Ph.D. Doctoral Research</option>
              <option>PSYC 721 Practicum Preparation</option>
              <option>PSYC 781 Internal Practicum</option>
              <option>PSYC 782 External Practicum</option>
              <option>PSYC 795 Directed Clinical Experience</option>
              <option>PSYC 798 Pre-internship</option>
              <option>PSYC 799 Internship</option>
              <option>RELE Religion Elective</option>
              <option>RELR 535 Religion: Course Required</option>
              <option>RELT Religion Elective</option>
              <option>Other Course</option>
            </Input>
            <p>
              <br />
            </p>
          </Col>
          <Col sm={2} />
        </Row>
        {/* failedCourseSelect */}
        {/* failedGradeSelect  */}
        <Row>
          <Col sm={4} />
          <Col sm={4} className="text-center">
            <Label for="failedGradeSelect">
              <i className="fa fa-chevron-right" /> Substandard Grade
            </Label>
            <Input
              type="select"
              id="failedGradeSelect"
              name="failedGradeSelect"
              value={failedGradeSelect}
              onChange={this.handleInputChange}
            >
              <option>B-</option>
              <option>C+</option>
              <option>C</option>
              <option>C-</option>
              <option>D+</option>
              <option>D</option>
              <option>D-</option>
              <option>F</option>
              <option>U</option>
            </Input>
            <p>
              <br />
            </p>
          </Col>
          <Col sm={4} />
        </Row>
        {/* // failedGradeSelect */}
        {/* failedCourseRetaken */}
        <Row>
          <Col className="text-center">
            <Label>
              <i className="fa fa-chevron-right" /> Did the student retake the course?
            </Label>
            <br />
            <Button
              className="btn-form"
              id="failedCourseRetaken"
              data-id="failedCourseRetaken"
              data-value="Yes"
              color={failedCourseRetaken === 'Yes' ? 'dark' : 'inverse'}
              onClick={this.onFailedCourseQuestionBtnClick}
              active={failedCourseRetaken === 'Yes'}
            >
              Yes
            </Button>
            <Button
              className="btn-form"
              id="failedCourseRetaken"
              data-id="failedCourseRetaken"
              data-value="No"
              color={failedCourseRetaken === 'No' ? 'dark' : 'inverse'}
              onClick={this.onFailedCourseQuestionBtnClick}
              active={failedCourseRetaken === 'No'}
            >
              No
            </Button>
            <p>
              <br />
            </p>
          </Col>
        </Row>
        {/* // failedCourseRetaken */}
        {failedCourseRetaken === 'Yes' && (
          <div>
            {/* retakenYearSelect */}
            <Row>
              <Col sm={2} />
              <Col sm={8} className="text-center">
                <Label for="retakenYearSelect">
                  <i className="fa fa-chevron-right" /> Year
                </Label>
                <Input
                  type="select"
                  id="retakenYearSelect"
                  name="retakenYearSelect"
                  value={retakenYearSelect}
                  onChange={this.handleInputChange}
                >
                  <option value="2022">2022-2023</option>
                  <option value="2021">2021-2022</option>
                  <option value="2020">2020-2021</option>
                  <option value="2019">2019-2020</option>
                  <option value="2018">2018-2019</option>
                </Input>
                <p>
                  <br />
                </p>
              </Col>
              <Col sm={2} />
            </Row>
            {/* retakenYearSelect */}
            {/* retakenAcademicTerm */}
            <Row>
              <Col className="text-center">
                <Label>
                  <i className="fa fa-chevron-right" /> Retaken term
                </Label>
                <Button
                  className="btn-form"
                  id="retakenAcademicTerm"
                  data-id="retakenAcademicTerm"
                  data-value="Summer"
                  color={retakenAcademicTerm === 'Summer' ? 'dark' : 'inverse'}
                  onClick={this.onFailedCourseQuestionBtnClick}
                  active={retakenAcademicTerm === 'Summer'}
                >
                  Summer
                </Button>
                <Button
                  className="btn-form"
                  id="retakenAcademicTerm"
                  data-id="retakenAcademicTerm"
                  data-value="Fall"
                  color={retakenAcademicTerm === 'Fall' ? 'dark' : 'inverse'}
                  onClick={this.onFailedCourseQuestionBtnClick}
                  active={retakenAcademicTerm === 'Fall'}
                >
                  Fall
                </Button>
                <Button
                  className="btn-form"
                  id="retakenAcademicTerm"
                  data-id="retakenAcademicTerm"
                  data-value="Winter"
                  color={retakenAcademicTerm === 'Winter' ? 'dark' : 'inverse'}
                  onClick={this.onFailedCourseQuestionBtnClick}
                  active={retakenAcademicTerm === 'Winter'}
                >
                  Winter
                </Button>
                <Button
                  className="btn-form"
                  id="retakenAcademicTerm"
                  data-id="retakenAcademicTerm"
                  data-value="Spring"
                  color={retakenAcademicTerm === 'Spring' ? 'dark' : 'inverse'}
                  onClick={this.onFailedCourseQuestionBtnClick}
                  active={retakenAcademicTerm === 'Spring'}
                >
                  Spring
                </Button>
                <p>
                  <br />
                </p>
              </Col>
            </Row>
            {/* // retakenAcademicTerm */}
            {/* retakenGradeSelect */}
            <Row>
              <Col sm={4} />
              <Col sm={4} className="text-center">
                <Label for="retakenGradeSelect">
                  <i className="fa fa-chevron-right" /> Retaken Grade
                </Label>

                {/* retakenGradeSelect = q5-s1-q4-s1-q2-s-retakenGrade  */}

                <Input
                  type="select"
                  id="retakenGradeSelect"
                  name="retakenGradeSelect"
                  value={retakenGradeSelect}
                  onChange={this.handleInputChange}
                >
                  <option>A</option>
                  <option>A-</option>
                  <option>B+</option>
                  <option>B</option>
                  <option>B-</option>
                  <option>C+</option>
                  <option>C</option>
                  <option>C-</option>
                  <option>D+</option>
                  <option>D</option>
                  <option>D-</option>
                  <option>F</option>
                  <option>S</option>
                  <option>U</option>
                </Input>
                <p>
                  <br />
                </p>
              </Col>
              <Col sm={4} />
            </Row>
            {/* // retakenGradeSelect */}
          </div>
        )}
        <Row>
          <Col className="text-center">
            <Button color="success" onClick={this.onSaveFailedCourseBtnClick}>
              Add Course
            </Button>
            <Button className="ml-2" color="danger" onClick={this.onCancelFailedCourseBtnClick}>
              Cancel
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  renderFailedCourses() {
    const { failedCourses, addNewFailedCourse } = this.state;
    return (
      <div>
        <Row className="justify-content-center">
          <Col xs="10" className="text-center">
            <h5 className="mt-4" style={{ textDecoration: 'underline' }}>
              Failed Courses
            </h5>
            {failedCourses.length === 0 && (
              <div>
                <p>No Failed Courses</p>
              </div>
            )}
            {failedCourses.length > 0 && (
              <div>
                {failedCourses.map((course, idx) => (
                  <div>
                    <p>
                      {course.FailedYear} - {course.FailedCourse}
                      <Button
                        className="ml-2"
                        color="link"
                        data-id={idx}
                        onClick={this.onRemoveFailedCourseBtnClick}
                      >
                        remove
                      </Button>
                    </p>
                  </div>
                ))}
              </div>
            )}
            {!addNewFailedCourse && (
              <Button onClick={this.onAddFailedCourseBtnClick}>Add Failed Course</Button>
            )}
          </Col>
        </Row>
        {addNewFailedCourse && this.renderAddFailedCourse()}
      </div>
    );
  }

  renderAcademicStanding() {
    const { formData } = this.state;
    return (
      <Row>
        <Col>
          <Widget
            title={
              <h5>
                {' '}
                <i className="fa fa-align-left" /> Academic Standing{' '}
              </h5>
            }
          >
            <FormGroup>
              <Form>
                {/* Academic Standing - Q1 */}
                <Row>
                  <Col className="text-center">
                    <Label>
                      <b>Q1</b> Current standing
                    </Label>
                    <br />
                    <Button
                      className="btn-form"
                      id="q1-b-goodStanding"
                      color={this.state.formData[0].data[10].value === 1 ? 'success' : 'inverse'}
                      onClick={() => this.onFrmBtnToggle(0, 10)}
                      active={this.state.formData[0].data[10].value === 1}
                    >
                      Good Standing
                    </Button>
                    <Button
                      className="btn-form"
                      id="q1-b-academicWarning"
                      color={this.state.formData[0].data[11].value === 1 ? 'warning' : 'inverse'}
                      onClick={() => this.onFrmBtnToggle(0, 11)}
                      active={this.state.formData[0].data[11].value === 1}
                    >
                      Academic Warning
                    </Button>
                    <Button
                      className="btn-form"
                      id="q1-b-clinicalWarning"
                      color={this.state.formData[0].data[12].value === 1 ? 'warning' : 'inverse'}
                      onClick={() => this.onFrmBtnToggle(0, 12)}
                      active={this.state.formData[0].data[12].value === 1}
                    >
                      Clinical Warning
                    </Button>
                    <Button
                      className="btn-form"
                      id="q1-b-academicProbation"
                      color={this.state.formData[0].data[13].value === 1 ? 'danger' : 'inverse'}
                      onClick={() => this.onFrmBtnToggle(0, 13)}
                      active={this.state.formData[0].data[13].value === 1}
                    >
                      Academic Probation
                    </Button>
                    <Button
                      className="btn-form"
                      id="q1-b-clinicalProbation"
                      color={this.state.formData[0].data[14].value === 1 ? 'danger' : 'inverse'}
                      onClick={() => this.onFrmBtnToggle(0, 14)}
                      active={this.state.formData[0].data[14].value === 1}
                    >
                      Clinical Probation
                    </Button>
                  </Col>
                </Row>
                <p>
                  <br />
                </p>
                {/* // Academic Standing - Q1 */}
                {/* Academic Standing - Q2 */}
                <Row>
                  <Col className="text-center">
                    <Label>
                      <b>Q2</b> Academic status
                    </Label>
                    <br />
                    <Button
                      className="btn-form"
                      id="q2-b-fullTime"
                      color={
                        this.state.formData[0].data[1].value === 'Full-Time' ? 'dark' : 'inverse'
                      }
                      onClick={() => this.onFrmBtnClick(0, 1, 'Full-Time')}
                      active={this.state.formData[0].data[1].value === 'Full-Time'}
                    >
                      Full-Time
                    </Button>
                    <Button
                      className="btn-form"
                      id="q2-b-leaveOfAbsence"
                      color={
                        this.state.formData[0].data[1].value === 'Leave of Absence'
                          ? 'warning'
                          : 'inverse'
                      }
                      onClick={() => this.onFrmBtnClick(0, 1, 'Leave of Absence')}
                      active={this.state.formData[0].data[1].value === 'Leave of Absence'}
                    >
                      Leave of Absence
                    </Button>
                    <Button
                      className="btn-form"
                      id="q2-b-leftProgram"
                      color={
                        this.state.formData[0].data[1].value === 'Left Program'
                          ? 'danger'
                          : 'inverse'
                      }
                      onClick={() => this.onFrmBtnClick(0, 1, 'Left Program')}
                      active={this.state.formData[0].data[1].value === 'Left Program'}
                    >
                      Left Program
                    </Button>
                    <Button
                      className="btn-form"
                      id="q2-b-other"
                      color={
                        this.state.formData[0].data[1].value === 'Other' ? 'danger' : 'inverse'
                      }
                      onClick={() => this.onFrmBtnClick(0, 1, 'Other')}
                      active={this.state.formData[0].data[1].value === 'Other'}
                    >
                      Other
                    </Button>
                  </Col>
                </Row>
                <p>
                  <br />
                </p>

                {/* hey, i thought the subquestions lived at the top of the page ... */}

                {/* Academic Standing - Q2 - subQ - Leave of Absence */}
                {(() => {
                  if (this.state.formData[0].data[1].value === 'Leave of Absence') {
                    return (
                      <div>
                        {/* Academic Standing - Q2 - sub Q1  */}
                        <div className="submitBlock">
                          <Row>
                            <Col className="text-center">
                              <p>
                                Hopefully they come back. In the meantime, we need some details.
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6} className="text-md-right">
                              <Label>
                                <i className="fa fa-chevron-right" /> Expected return date
                              </Label>
                            </Col>

                            {/*

  i wasn't sure if swapping out
  this id - LeaveOfAbsenceReturn -
  would gum up the works. in our new
  convention, it'd be q2-s1-LA-q1-t-leaveOfAbsenceReturn
  -LA- = Leave of Absence
  -LA- is necessary because we have several
  sets of subq's based on the response

*/}

                            <Col md={3}>
                              <Input
                                type="date"
                                id="q2-s1-LEAVE-q1-returnDate"
                                name="expectedReturnDate"
                                className="dateTextbox"
                                value={this.state.expectedReturnDate}
                                onChange={this.handleInputChange}
                              />
                            </Col>
                          </Row>
                          <p>
                            <br />
                          </p>
                          {/* // Academic Standing - Q2 - sub Q1 */}
                          {/* Academic Standing - Q2 - sub Q2  */}
                          <Row>
                            <Col md={6} className="text-md-right">
                              <Label for="reasonLeftProgramSelect">
                                <i className="fa fa-chevron-right" /> Reason left program
                              </Label>
                            </Col>

                            {/*

  same deal here - q2-s1-LA-q2-dd-reasonLeftProgram
  for dropdown menus, i'm using -dd-
  like -t- means textbox

*/}

                            <Col md={6}>
                              <Input
                                type="select"
                                id="q2-s1-LEAVE-q2-s-reasonLeftProgram"
                                name="reasonLeftProgramSelect"
                                value={this.state.reasonLeftProgramSelect}
                                onChange={this.handleInputChange}
                              >
                                <option>Successful completion of program</option>
                                <option>Academic reasons</option>
                                <option>Awarded terminal master's degree</option>
                                <option>Change in psychology area specialization</option>
                                <option>Change in career / Employed elsewhere</option>
                                <option>Death of student</option>
                                <option>Did not return from absence</option>
                                <option>Dissmissed - failed program requirements</option>
                                <option>Family or relationship matters</option>
                                <option>Financial</option>
                                <option>Health / Medical</option>
                                <option>New interest outside of psychology</option>
                                <option>No reason provided for leaving program</option>
                                <option>Personal reasons</option>
                                <option>Student relocated</option>
                                <option>Transferred to a different university</option>
                                <option>Transferred to follow academic advisor</option>
                                <option>Voluntary withdrawal - academic difficulties</option>
                                <option>Other reasons</option>
                              </Input>
                            </Col>
                          </Row>
                          {(() => {
                            if (this.state.reasonLeftProgramSelect === 'Other reasons') {
                              return (
                                <div>
                                  <p>
                                    <br />
                                  </p>
                                  <Row>
                                    <Col className="text-center">
                                      <Label>
                                        <i className="fa fa-chevron-right" /> Please specify
                                      </Label>
                                      <br />
                                      <Input
                                        type="text"
                                        id="q2-s1-LA-q2-t-otherReason"
                                        name="otherReason"
                                        value={this.state.otherReason}
                                        onChange={this.handleInputChange}
                                      />
                                      <p>
                                        <br />
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }
                          })()}
                        </div>
                        {/* // Academic Standing - Q2 - sub Q2  */}
                        <p>
                          <br />
                        </p>
                      </div>
                    );
                  }
                })()}
                {/* // Academic Standing - Q2 - subQ - Leave of Absence */}
                {/* Academic Standing - Q2 - subQ - Left Program */}
                {(() => {
                  if (this.state.formData[0].data[1].value === 'Left Program') {
                    return (
                      <div>
                        {/* Academic Standing - Q2 - sub Q1  */}
                        <div className="submitBlock">
                          <Row>
                            <Col className="text-center">
                              <p>Shucks, we need some details.</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6} className="text-md-right">
                              <Label>
                                <i className="fa fa-chevron-right" /> Date left program
                              </Label>
                            </Col>
                            {/*  DateLeftProgram = q2-s1-LP-q1-t-dateLeftProgram  */}
                            <Col md={3}>
                              <Input
                                type="date"
                                id="q2-s1-LEFT-q1-dateLeftProgram"
                                name="dateLeftProgram"
                                className="dateTextbox"
                                value={this.state.dateLeftProgram}
                                onChange={this.handleInputChange}
                              />
                            </Col>
                          </Row>
                          <p>
                            <br />
                          </p>
                          {/* // Academic Standing - Q2 - sub Q1  */}
                          {/* Academic Standing - Q2 - sub Q2  */}
                          <Row>
                            <Col md={6} className="text-md-right">
                              <Label for="reasonLeftProgramSelect">
                                <i className="fa fa-chevron-right" /> Reason left program
                              </Label>
                            </Col>
                            {/* reasonLeftProgramSelect = q2-s1-LP-q2-s-reasonLeftProgram */}
                            <Col md={6}>
                              <Input
                                type="select"
                                id="q2-s1-LEFT-q2-s-reasonLeftProgram"
                                name="reasonLeftProgramSelect"
                                value={this.state.reasonLeftProgramSelect}
                                onChange={this.handleInputChange}
                              >
                                <option>Successful completion of program</option>
                                <option>Academic reasons</option>
                                <option>Awarded terminal master's degree</option>
                                <option>Change in psychology area specialization</option>
                                <option>Change in career / Employed elsewhere</option>
                                <option>Death of student</option>
                                <option>Did not return from absence</option>
                                <option>Dissmissed - failed program requirements</option>
                                <option>Family or relationship matters</option>
                                <option>Financial</option>
                                <option>Health / Medical</option>
                                <option>New interest outside of psychology</option>
                                <option>No reason provided for leaving program</option>
                                <option>Personal reasons</option>
                                <option>Student relocated</option>
                                <option>Transferred to a different university</option>
                                <option>Transferred to follow academic advisor</option>
                                <option>Voluntary withdrawal - academic difficulties</option>
                                <option>Other reasons</option>
                              </Input>
                            </Col>
                          </Row>
                          {(() => {
                            if (this.state.reasonLeftProgramSelect === 'Other reasons') {
                              return (
                                <div>
                                  <p>
                                    <br />
                                  </p>
                                  <Row>
                                    <Col className="text-center">
                                      <Label>
                                        <i className="fa fa-chevron-right" /> Please specify
                                      </Label>
                                      <br />
                                      <Input
                                        type="text"
                                        id="q2-s1-LP-q2-t-otherReasonLeftProgram"
                                        name="otherReason"
                                        value={this.state.otherReason}
                                        onChange={this.handleInputChange}
                                      />
                                      <p>
                                        <br />
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }
                          })()}
                        </div>
                        {/* // Academic Standing - Q2 - sub Q2  */}
                        <p>
                          <br />
                        </p>
                      </div>
                    );
                  }
                })()}
                {(() => {
                  if (this.state.formData[0].data[1].value === 'Other') {
                    return (
                      <div>
                        {/* Academic Standing - Q2 - sub Q1  */}
                        <div className="submitBlock">
                          <Row>
                            <Col className="text-center">
                              <Label>
                                <i className="fa fa-chevron-right" /> Please specify
                              </Label>
                              <br />
                              <Input
                                type="text"
                                id="q2-s1-LA-q2-t-otherReason"
                                name="otherReason"
                                value={this.state.otherReason}
                                onChange={this.handleInputChange}
                              />
                              <p>
                                <br />
                              </p>
                            </Col>
                          </Row>
                        </div>
                        {/* // Academic Standing - Q2 - sub Q2  */}
                        <p>
                          <br />
                        </p>
                      </div>
                    );
                  }
                })()}
                {/* end Q2 subQs */}
                {/* Academic Standing - Q3 */}
                <FormGroup>
                  <Row>
                    <Col className="text-center">
                      <Label>
                        <b>Q3</b> Tuition status
                      </Label>
                      <br />
                      <Button
                        className="btn-form"
                        id="q3-b-block"
                        color={
                          this.state.formData[0].data[6].value === 'Block' ? 'dark' : 'inverse'
                        }
                        onClick={() => this.onFrmBtnClick(0, 6, 'Block')}
                        active={this.state.formData[0].data[6].value === 'Block'}
                      >
                        Block
                      </Button>
                      <Button
                        className="btn-form"
                        id="q3-b-offBlock"
                        color={
                          this.state.formData[0].data[6].value === 'Off-Block' ? 'dark' : 'inverse'
                        }
                        onClick={() => this.onFrmBtnClick(0, 6, 'Off-Block')}
                        active={this.state.formData[0].data[6].value === 'Off-Block'}
                      >
                        Off-Block
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
                <p>
                  <br />
                </p>
                {/* // Academic Standing - Q3 */}
                {/* Academic Standing - Q4 */}
                <FormGroup>
                  <Row>
                    <Col className="text-center">
                      <Label>
                        <b>Q4</b> Combined GPA
                      </Label>
                      <br />
                      <Input
                        invalid={this.state.gpaError}
                        className="gpa-textbox-width"
                        id="q4-t-GPA"
                        type="text"
                        name="GPA"
                        value={this.state.GPA}
                        onChange={this.handleInputChange}
                        onBlur={this.validateGPA}
                      />
                      <FormFeedback>{this.state.gpaErrorMsg}</FormFeedback>
                    </Col>
                  </Row>
                </FormGroup>
                <p>
                  <br />
                </p>
                {/* // Academic Standing - Q4 */}
                {/* Academic Standing - Q5 */}
                <Row>
                  <Col className="text-center">
                    <Label>
                      <b>Q5</b> Did the student fail any courses?
                    </Label>
                    <br />
                    <Button
                      className="btn-form"
                      id="q5-b-yes"
                      color={this.state.formData[0].data[8].value === 'Yes' ? 'dark' : 'inverse'}
                      onClick={() => this.onFrmBtnClick(0, 8, 'Yes')}
                      active={this.state.formData[0].data[8].value === 'Yes'}
                    >
                      Yes
                    </Button>
                    <Button
                      className="btn-form"
                      id="q5-b-no"
                      color={this.state.formData[0].data[8].value === 'No' ? 'dark' : 'inverse'}
                      onClick={() => this.onFrmBtnClick(0, 8, 'No')}
                      active={this.state.formData[0].data[8].value === 'No'}
                    >
                      No
                    </Button>
                  </Col>
                </Row>
                {/* Academic Standing - Q5 - subQ - Failed Course */}
                {this.state.formData[0].data[8].value === 'Yes' && this.renderFailedCourses()}
                {/* end Q2 subQs */}
                <p>
                  <br />
                </p>
                <FormGroup row className="submitBlock">
                  <Col md={12} className="text-center">
                    <Button
                      className="ml-xs"
                      id="s0-b-save"
                      color="info"
                      onClick={(e) => this.saveForm(e, 'inProgress')}
                    >
                      Save
                    </Button>
                    <Button
                      className="ml-xs"
                      id="s0-b-cont"
                      color="info"
                      onClick={(e) => this.onSectionBtnClick(e, 1)}
                    >
                      Continue
                    </Button>
                  </Col>
                </FormGroup>
              </Form>
            </FormGroup>
          </Widget>
        </Col>
      </Row>
    );
  }

  renderStudentRatings() {
    const { formData, GPA, currentProgram, year } = this.state;
    const renderQ14 =
      (currentProgram === 'Ph.D.' && year >= 7) || (currentProgram === 'Psy.D.' && year >= 6);
    return (
      /* Student Ratings */
      <Row>
        <Col>
          <Widget
            title={
              <h5>
                {' '}
                <i className="fa fa-align-left" /> Student Ratings{' '}
              </h5>
            }
          >
            {/* this alert only displays in the active form, not the readout  */}

            <Row>
              <Col>
                <Alert color="primary">
                  <p>For the next set of questions, please use the following guidelines:</p>
                  <Row>
                    <Col xs={10}>
                      <Row>
                        <Col sm={2} className="text-center">
                          <strong>Rating</strong>
                        </Col>
                        <Col sm={3}>
                          <strong>Evaluation</strong>
                        </Col>
                        <Col sm={7}>
                          <strong>Academic Rating</strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={2} className="text-center">
                          <strong>1</strong>
                        </Col>
                        <Col sm={3}>Unacceptable</Col>
                        <Col sm={7}>GPA below 3</Col>
                      </Row>
                      <Row>
                        <Col sm={2} className="text-center">
                          <strong>2</strong>
                        </Col>
                        <Col sm={3}>Below expectations</Col>
                        <Col sm={7}>GPA below 3.5</Col>
                      </Row>
                      <Row>
                        <Col sm={2} className="text-center">
                          <strong>3</strong>
                        </Col>
                        <Col sm={3}>Meets expectations</Col>
                        <Col sm={7}>GPA equal to 3.5 - 3.69</Col>
                      </Row>
                      <Row>
                        <Col sm={2} className="text-center">
                          <strong>4</strong>
                        </Col>
                        <Col sm={3}>Exceeds expectations</Col>
                        <Col sm={7}>GPA equal to 3.7 - 3.89</Col>
                      </Row>
                      <Row>
                        <Col sm={2} className="text-center">
                          <strong>5</strong>
                        </Col>
                        <Col sm={3}>Outstanding</Col>
                        <Col sm={7}>GPA equal to / greater than 3.9</Col>
                      </Row>
                    </Col>
                    <Col xs={2} className="text-center">
                      <div className="GPA">
                        GPA
                        <h1>{GPA}</h1>
                      </div>
                    </Col>
                  </Row>
                </Alert>
              </Col>
            </Row>
            <p>
              <br />
            </p>
            <FormGroup>
              <Form>
                {/* Student Ratings - Q6 */}
                <FormGroup>
                  <Row>
                    <Col className="text-center">
                      <Label>
                        <b>Q6</b> Suggested overall academic rating
                      </Label>
                      <br />
                      <Button
                        className="btn-form"
                        id="q6-b-1"
                        color={this.state.formData[1].data[0].value === 1 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 0, 1)}
                        active={this.state.formData[1].data[0].value === 1}
                      >
                        1
                      </Button>
                      <Button
                        className="btn-form"
                        id="q6-b-2"
                        color={this.state.formData[1].data[0].value === 2 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 0, 2)}
                        active={this.state.formData[1].data[0].value === 2}
                      >
                        2
                      </Button>
                      <Button
                        className="btn-form"
                        id="q6-b-3"
                        color={this.state.formData[1].data[0].value === 3 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 0, 3)}
                        active={this.state.formData[1].data[0].value === 3}
                      >
                        3
                      </Button>
                      <Button
                        className="btn-form"
                        id="q6-b-4"
                        color={this.state.formData[1].data[0].value === 4 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 0, 4)}
                        active={this.state.formData[1].data[0].value === 4}
                      >
                        4
                      </Button>
                      <Button
                        className="btn-form"
                        id="q6-b-5"
                        color={this.state.formData[1].data[0].value === 5 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 0, 5)}
                        active={this.state.formData[1].data[0].value === 5}
                      >
                        5
                      </Button>
                      <Button
                        className="btn-form"
                        id="q6-b-NA"
                        color={this.state.formData[1].data[0].value === 0 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 0, 0)}
                        active={this.state.formData[1].data[0].value === 0}
                      >
                        N/A
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
                <p>
                  <br />
                </p>
                {/* // Student Ratings - Q6 */}
                {/* Academic Ratings Comments */}
                <FormGroup>
                  <Row className="justify-content-center">
                    <Col sm={10} className="text-center">
                      <Label>
                        <b>Academic comments</b>
                      </Label>
                      <br />
                      <Input
                        id="t-academicComments"
                        rows="4"
                        type="textarea"
                        name="academicComments"
                        value={this.state.academicComments}
                        onChange={this.handleInputChange}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <p>
                  <br />
                </p>
                {/* // Academic Ratings Comments */}
                {/* Student Ratings - Q7 */}
                <FormGroup>
                  <Row>
                    <Col className="text-center">
                      <Label>
                        <b>Q7</b> Suggested overall research rating
                      </Label>
                      <br />
                      <Button
                        className="btn-form"
                        id="q7-b-1"
                        color={this.state.formData[1].data[1].value === 1 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 1, 1)}
                        active={this.state.formData[1].data[1].value === 1}
                      >
                        1
                      </Button>
                      <Button
                        className="btn-form"
                        id="q7-b-2"
                        color={this.state.formData[1].data[1].value === 2 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 1, 2)}
                        active={this.state.formData[1].data[1].value === 2}
                      >
                        2
                      </Button>
                      <Button
                        className="btn-form"
                        id="q7-b-3"
                        color={this.state.formData[1].data[1].value === 3 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 1, 3)}
                        active={this.state.formData[1].data[1].value === 3}
                      >
                        3
                      </Button>
                      <Button
                        className="btn-form"
                        id="q7-b-4"
                        color={this.state.formData[1].data[1].value === 4 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 1, 4)}
                        active={this.state.formData[1].data[1].value === 4}
                      >
                        4
                      </Button>
                      <Button
                        className="btn-form"
                        id="q7-b-5"
                        color={this.state.formData[1].data[1].value === 5 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 1, 5)}
                        active={this.state.formData[1].data[1].value === 5}
                      >
                        5
                      </Button>
                      <Button
                        className="btn-form"
                        id="q7-b-NA"
                        color={this.state.formData[1].data[1].value === 0 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 1, 0)}
                        active={this.state.formData[1].data[1].value === 0}
                      >
                        N/A
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
                <p>
                  <br />
                </p>
                {/* // Student Ratings - Q7 */}
                {currentProgram === 'Ph.D.' && (
                  <div>
                    {/* Student Ratings - Q8 - Ph.D. Only */}
                    <FormGroup>
                      <Row>
                        <Col className="text-center">
                          <Label>
                            <b>Q8</b> Student's commitment to research lab
                          </Label>
                          <br />
                          <Button
                            className="btn-form"
                            id="q8-b-1"
                            color={this.state.formData[1].data[2].value === 1 ? 'dark' : 'inverse'}
                            onClick={() => this.onFrmBtnClick(1, 2, 1)}
                            active={this.state.formData[1].data[2].value === 1}
                          >
                            1
                          </Button>
                          <Button
                            className="btn-form"
                            id="q8-b-2"
                            color={this.state.formData[1].data[2].value === 2 ? 'dark' : 'inverse'}
                            onClick={() => this.onFrmBtnClick(1, 2, 2)}
                            active={this.state.formData[1].data[2].value === 2}
                          >
                            2
                          </Button>
                          <Button
                            className="btn-form"
                            id="q8-b-3"
                            color={this.state.formData[1].data[2].value === 3 ? 'dark' : 'inverse'}
                            onClick={() => this.onFrmBtnClick(1, 2, 3)}
                            active={this.state.formData[1].data[2].value === 3}
                          >
                            3
                          </Button>
                          <Button
                            className="btn-form"
                            id="q8-b-4"
                            color={this.state.formData[1].data[2].value === 4 ? 'dark' : 'inverse'}
                            onClick={() => this.onFrmBtnClick(1, 2, 4)}
                            active={this.state.formData[1].data[2].value === 4}
                          >
                            4
                          </Button>
                          <Button
                            className="btn-form"
                            id="q8-b-5"
                            color={this.state.formData[1].data[2].value === 5 ? 'dark' : 'inverse'}
                            onClick={() => this.onFrmBtnClick(1, 2, 5)}
                            active={this.state.formData[1].data[2].value === 5}
                          >
                            5
                          </Button>
                          <Button
                            className="btn-form"
                            id="q8-b-NA"
                            color={this.state.formData[1].data[2].value === 0 ? 'dark' : 'inverse'}
                            onClick={() => this.onFrmBtnClick(1, 2, 0)}
                            active={this.state.formData[1].data[2].value === 0}
                          >
                            N/A
                          </Button>
                        </Col>
                      </Row>
                    </FormGroup>
                    <p>
                      <br />
                    </p>
                    {/* // Student Ratings - Q8 - Ph.D. Only */}
                    {/* Student Ratings - Q9 - Ph.D. Only */}
                    <FormGroup>
                      <Row>
                        <Col className="text-center">
                          <Label>
                            <b>Q9</b> Student's interest and motivation to conduct research
                          </Label>
                          <br />
                          <Button
                            className="btn-form"
                            id="q9-b-1"
                            color={this.state.formData[1].data[3].value === 1 ? 'dark' : 'inverse'}
                            onClick={() => this.onFrmBtnClick(1, 3, 1)}
                            active={this.state.formData[1].data[3].value === 1}
                          >
                            1
                          </Button>
                          <Button
                            className="btn-form"
                            id="q9-b-2"
                            color={this.state.formData[1].data[3].value === 2 ? 'dark' : 'inverse'}
                            onClick={() => this.onFrmBtnClick(1, 3, 2)}
                            active={this.state.formData[1].data[3].value === 2}
                          >
                            2
                          </Button>
                          <Button
                            className="btn-form"
                            id="q9-b-3"
                            color={this.state.formData[1].data[3].value === 3 ? 'dark' : 'inverse'}
                            onClick={() => this.onFrmBtnClick(1, 3, 3)}
                            active={this.state.formData[1].data[3].value === 3}
                          >
                            3
                          </Button>
                          <Button
                            className="btn-form"
                            id="q9-b-4"
                            color={this.state.formData[1].data[3].value === 4 ? 'dark' : 'inverse'}
                            onClick={() => this.onFrmBtnClick(1, 3, 4)}
                            active={this.state.formData[1].data[3].value === 4}
                          >
                            4
                          </Button>
                          <Button
                            className="btn-form"
                            id="q9-b-5"
                            color={this.state.formData[1].data[3].value === 5 ? 'dark' : 'inverse'}
                            onClick={() => this.onFrmBtnClick(1, 3, 5)}
                            active={this.state.formData[1].data[3].value === 5}
                          >
                            5
                          </Button>
                          <Button
                            className="btn-form"
                            id="q9-b-NA"
                            color={this.state.formData[1].data[3].value === 0 ? 'dark' : 'inverse'}
                            onClick={() => this.onFrmBtnClick(1, 3, 0)}
                            active={this.state.formData[1].data[3].value === 0}
                          >
                            N/A
                          </Button>
                        </Col>
                      </Row>
                    </FormGroup>
                    <p>
                      <br />
                    </p>
                    {/* // Student Ratings - Q9 - Ph.D. Only */}
                    {/* Student Ratings - Q10 - Ph.D. Only */}
                    <FormGroup>
                      <Row>
                        <Col className="text-center">
                          <Label>
                            <b>Q10</b> Student engagement in activities that will increase higher
                            research productivity
                          </Label>
                          <br />
                          <Button
                            className="btn-form"
                            id="q10-b-1"
                            color={this.state.formData[1].data[4].value === 1 ? 'dark' : 'inverse'}
                            onClick={() => this.onFrmBtnClick(1, 4, 1)}
                            active={this.state.formData[1].data[4].value === 1}
                          >
                            1
                          </Button>
                          <Button
                            className="btn-form"
                            id="q10-b-2"
                            color={this.state.formData[1].data[4].value === 2 ? 'dark' : 'inverse'}
                            onClick={() => this.onFrmBtnClick(1, 4, 2)}
                            active={this.state.formData[1].data[4].value === 2}
                          >
                            2
                          </Button>
                          <Button
                            className="btn-form"
                            id="q10-b-3"
                            color={this.state.formData[1].data[4].value === 3 ? 'dark' : 'inverse'}
                            onClick={() => this.onFrmBtnClick(1, 4, 3)}
                            active={this.state.formData[1].data[4].value === 3}
                          >
                            3
                          </Button>
                          <Button
                            className="btn-form"
                            id="q10-b-4"
                            color={this.state.formData[1].data[4].value === 4 ? 'dark' : 'inverse'}
                            onClick={() => this.onFrmBtnClick(1, 4, 4)}
                            active={this.state.formData[1].data[4].value === 4}
                          >
                            4
                          </Button>
                          <Button
                            className="btn-form"
                            id="q10-b-5"
                            color={this.state.formData[1].data[4].value === 5 ? 'dark' : 'inverse'}
                            onClick={() => this.onFrmBtnClick(1, 4, 5)}
                            active={this.state.formData[1].data[4].value === 5}
                          >
                            5
                          </Button>
                          <Button
                            className="btn-form"
                            id="q10-b-NA"
                            color={this.state.formData[1].data[4].value === 0 ? 'dark' : 'inverse'}
                            onClick={() => this.onFrmBtnClick(1, 4, 0)}
                            active={this.state.formData[1].data[4].value === 0}
                          >
                            N/A
                          </Button>
                        </Col>
                      </Row>
                    </FormGroup>
                    <p>
                      <br />
                    </p>
                    {/* // Student Ratings - Q10 - Ph.D. Only */}
                  </div>
                )}
                {currentProgram === 'Psy.D.' && (
                  <div>
                    <Row className="justify-content-center">
                      <Col sm={10} className="text-center">
                        <Label>
                          <b>Q8 through Q10 are Ph.D. only</b>
                        </Label>
                      </Col>
                    </Row>
                    <p>
                      <br />
                    </p>
                  </div>
                )}
                {/* Student Ratings - Q11 */}
                <FormGroup>
                  <Row>
                    <Col className="text-center">
                      <Label>
                        <b>Q11</b> Student's commitment to the degree model
                      </Label>
                      <br />
                      <Button
                        className="btn-form"
                        id="q11-b-1"
                        color={this.state.formData[1].data[5].value === 1 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 5, 1)}
                        active={this.state.formData[1].data[5].value === 1}
                      >
                        1
                      </Button>
                      <Button
                        className="btn-form"
                        id="q11-b-2"
                        color={this.state.formData[1].data[5].value === 2 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 5, 2)}
                        active={this.state.formData[1].data[5].value === 2}
                      >
                        2
                      </Button>
                      <Button
                        className="btn-form"
                        id="q11-b-3"
                        color={this.state.formData[1].data[5].value === 3 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 5, 3)}
                        active={this.state.formData[1].data[5].value === 3}
                      >
                        3
                      </Button>
                      <Button
                        className="btn-form"
                        id="q11-b-4"
                        color={this.state.formData[1].data[5].value === 4 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 5, 4)}
                        active={this.state.formData[1].data[5].value === 4}
                      >
                        4
                      </Button>
                      <Button
                        className="btn-form"
                        id="q11-b-5"
                        color={this.state.formData[1].data[5].value === 5 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 5, 5)}
                        active={this.state.formData[1].data[5].value === 5}
                      >
                        5
                      </Button>
                      <Button
                        className="btn-form"
                        id="q11-b-NA"
                        color={this.state.formData[1].data[5].value === 0 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 5, 0)}
                        active={this.state.formData[1].data[5].value === 0}
                      >
                        N/A
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
                <p>
                  <br />
                </p>
                {/* // Student Ratings - Q11 */}
                {/* Research Comments */}
                <FormGroup>
                  <Row className="justify-content-center">
                    <Col sm={10} className="text-center">
                      <Label>
                        <b>Research comments</b>
                      </Label>
                      <br />
                      <Input
                        id="t-researchComments"
                        rows="4"
                        type="textarea"
                        name="researchComments"
                        value={this.state.researchComments}
                        onChange={this.handleInputChange}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <p>
                  <br />
                </p>
                {/* // Research Comments */}
                {/* Student Ratings - Q12 */}
                <FormGroup>
                  <Row>
                    <Col className="text-center">
                      <Label>
                        <b>Q12</b> Suggested overall clinical rating
                      </Label>
                      <br />
                      <Button
                        className="btn-form"
                        id="q12-b-1"
                        color={this.state.formData[1].data[6].value === 1 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 6, 1)}
                        active={this.state.formData[1].data[6].value === 1}
                      >
                        1
                      </Button>
                      <Button
                        className="btn-form"
                        id="q12-b-2"
                        color={this.state.formData[1].data[6].value === 2 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 6, 2)}
                        active={this.state.formData[1].data[6].value === 2}
                      >
                        2
                      </Button>
                      <Button
                        className="btn-form"
                        id="q12-b-3"
                        color={this.state.formData[1].data[6].value === 3 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 6, 3)}
                        active={this.state.formData[1].data[6].value === 3}
                      >
                        3
                      </Button>
                      <Button
                        className="btn-form"
                        id="q12-b-4"
                        color={this.state.formData[1].data[6].value === 4 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 6, 4)}
                        active={this.state.formData[1].data[6].value === 4}
                      >
                        4
                      </Button>
                      <Button
                        className="btn-form"
                        id="q12-b-5"
                        color={this.state.formData[1].data[6].value === 5 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 6, 5)}
                        active={this.state.formData[1].data[6].value === 5}
                      >
                        5
                      </Button>
                      <Button
                        className="btn-form"
                        id="q12-b-NA"
                        color={this.state.formData[1].data[6].value === 0 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 6, 0)}
                        active={this.state.formData[1].data[6].value === 0}
                      >
                        N/A
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
                <p>
                  <br />
                </p>
                {/* // Student Ratings - Q12 */}
                {/* Clinical Comments */}
                <FormGroup>
                  <Row className="justify-content-center">
                    <Col sm={10} className="text-center">
                      <Label>
                        <b>Clinical comments</b>
                      </Label>
                      <br />
                      <Input
                        id="t-clinicalComments"
                        rows="4"
                        type="textarea"
                        name="clinicalComments"
                        value={this.state.clinicalComments}
                        onChange={this.handleInputChange}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <p>
                  <br />
                </p>
                {/* // Clinical Comments */}
                {/* Student Ratings - Q13 */}
                <FormGroup>
                  <Row>
                    <Col className="text-center">
                      <Label>
                        <b>Q13</b> Suggested overall professionalism rating
                      </Label>
                      <br />
                      <Button
                        className="btn-form"
                        id="q13-b-1"
                        color={this.state.formData[1].data[7].value === 1 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 7, 1)}
                        active={this.state.formData[1].data[7].value === 1}
                      >
                        1
                      </Button>
                      <Button
                        className="btn-form"
                        id="q13-b-2"
                        color={this.state.formData[1].data[7].value === 2 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 7, 2)}
                        active={this.state.formData[1].data[7].value === 2}
                      >
                        2
                      </Button>
                      <Button
                        className="btn-form"
                        id="q13-b-3"
                        color={this.state.formData[1].data[7].value === 3 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 7, 3)}
                        active={this.state.formData[1].data[7].value === 3}
                      >
                        3
                      </Button>
                      <Button
                        className="btn-form"
                        id="q13-b-4"
                        color={this.state.formData[1].data[7].value === 4 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 7, 4)}
                        active={this.state.formData[1].data[7].value === 4}
                      >
                        4
                      </Button>
                      <Button
                        className="btn-form"
                        id="q13-b-5"
                        color={this.state.formData[1].data[7].value === 5 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 7, 5)}
                        active={this.state.formData[1].data[7].value === 5}
                      >
                        5
                      </Button>
                      <Button
                        className="btn-form"
                        id="q13-b-NA"
                        color={this.state.formData[1].data[7].value === 0 ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 7, 0)}
                        active={this.state.formData[1].data[7].value === 0}
                      >
                        N/A
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
                <p>
                  <br />
                </p>
                {/* // Student Ratings - Q13 */}
                {/* Professionalism Comments */}
                <Row className="justify-content-center">
                  <Col sm={10} className="text-center">
                    <Label>
                      <b>Professionalism comments</b>
                    </Label>
                    <br />
                    <Input
                      id="t-professionalismComments"
                      rows="4"
                      type="textarea"
                      name="professionalismComments"
                      value={this.state.professionalismComments}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                </Row>
                <p>
                  <br />
                </p>
                {/* // Professionalism Comments */}
                {renderQ14 && (
                  <div>
                    {/* Reason for Program Extension Comments */}
                    {/* Ph.D. in year 7 or greater or Psy.D. in year 6 or greater only */}
                    <Row className="justify-content-center">
                      <Col sm={10} className="text-center">
                        <Label>
                          <b>Reason for program extension comments</b>
                        </Label>
                        <br />
                        <Input
                          id="t-programExtensionComments"
                          rows="4"
                          type="textarea"
                          name="programExtensionComments"
                          value={this.state.programExtensionComments}
                          onChange={this.handleInputChange}
                        />
                      </Col>
                    </Row>
                    <p>
                      <br />
                    </p>
                    {/* // Reason for Program Extension Comments */}
                  </div>
                )}
                {!renderQ14 && (
                  <div>
                    <Row className="justify-content-center">
                      <Col sm={10} className="text-center">
                        <Label>
                          <b>Q14 Year 7+ Ph.D. and Year 6+ Psy.D. only</b>
                        </Label>
                      </Col>
                    </Row>
                    <p>
                      <br />
                    </p>
                  </div>
                )}
                <FormGroup row className="submitBlock">
                  <Col className="text-center">
                    <Button
                      className="ml-xs"
                      id="s1-b-pre"
                      color="inverse"
                      onClick={(e) => this.onSectionBtnClick(e, -1)}
                    >
                      Previous
                    </Button>
                    <Button
                      className="ml-xs"
                      id="s1-b-save"
                      color="info"
                      onClick={(e) => this.saveForm(e, 'inProgress')}
                    >
                      Save
                    </Button>
                    <Button
                      className="ml-xs"
                      id="s2-b-submit"
                      color="info"
                      onClick={(e) => this.saveForm(e, 'completed')}
                    >
                      Submit
                    </Button>
                  </Col>
                </FormGroup>
              </Form>
            </FormGroup>
          </Widget>
        </Col>
      </Row>
    );
  }

  renderFacultyReadout() {
    const {
      formData,
      dateLeftProgram,
      expectedReturnDate,
      reasonLeftProgramSelect,
      otherReason,
      failedCourses,
      academicComments,
      researchComments,
      clinicalComments,
      professionalismComments,
      programExtensionComments,
      currentProgram,
      year,
    } = this.state;
    const reason = otherReason.length > 0 ? otherReason : reasonLeftProgramSelect;
    const renderReason =
      formData[0].data[1].value === 'Leave of Absence' ||
      formData[0].data[1].value === 'Left Program' ||
      formData[0].data[1].value === 'Other';
    const renderQ14 =
      (currentProgram === 'Ph.D.' && year >= 7) || (currentProgram === 'Psy.D.' && year >= 6);
    return (
      <div>
        {/* Student Ratings */}
        <Row>
          <Col>
            <Widget
              title={
                <div>
                  <h5>
                    {' '}
                    <i className="fa fa-align-left" /> Academic Standing{' '}
                    <Button
                      className="pull-right"
                      id="readout-b-editForm"
                      color="info"
                      onClick={(e) => this.onEditFrmBtnClick()}
                    >
                      Edit Form
                    </Button>
                  </h5>
                </div>
              }
            >
              {/* Current Standing */}
              {/* Old Form, render single value */}
              {formData[0].data.length === 10 && (
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      <Label>
                        <b>Q1</b>
                      </Label>{' '}
                      Current standing: <b className="ml-3">{formData[0].data[0].value}</b>
                    </p>
                  </Col>
                </Row>
              )}
              {/* New Form, render all values */}
              {formData[0].data.length === 15 && (
                <Row className="justify-content-center mt-4">
                  <Col className="text-center">
                    <Label>
                      <u>
                        <b>Q1</b> Current Standing
                      </u>
                    </Label>
                  </Col>
                  <Col xs={12} className="mt-2 text-center">
                    <p>
                      Good Standing:{' '}
                      <b className="ml-3">{formData[0].data[10].value === 1 ? 'Yes' : 'No'}</b>
                    </p>
                    <p>
                      Academic Warning:{' '}
                      <b className="ml-3">{formData[0].data[11].value === 1 ? 'Yes' : 'No'}</b>
                    </p>
                    <p>
                      Clinical Warning:{' '}
                      <b className="ml-3">{formData[0].data[12].value === 1 ? 'Yes' : 'No'}</b>
                    </p>
                    <p>
                      Academic Probation:{' '}
                      <b className="ml-3">{formData[0].data[13].value === 1 ? 'Yes' : 'No'}</b>
                    </p>
                    <p>
                      Clinical Probation:{' '}
                      <b className="ml-3">{formData[0].data[14].value === 1 ? 'Yes' : 'No'}</b>
                    </p>
                  </Col>
                </Row>
              )}
              <p>
                <br />
              </p>
              {/* // Current Standing */}
              {/* Academic Status */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q2</b>
                    </Label>{' '}
                    Academic status: <b className="ml-3">{formData[0].data[1].value}</b>
                  </p>
                </Col>
              </Row>
              {formData[0].data[1].value === 'Leave of Absence' && (
                <Row>
                  <Col className="text-center">
                    <p className="readout">
                      Expected return date: <b className="ml-3">{expectedReturnDate}</b>
                    </p>
                  </Col>
                </Row>
              )}
              {formData[0].data[1].value === 'Left Program' && (
                <Row>
                  <Col className="text-center">
                    <p className="readout">
                      Date left program: <b className="ml-3">{dateLeftProgram}</b>
                    </p>
                  </Col>
                </Row>
              )}
              {renderReason && (
                <Row>
                  <Col className="text-center">
                    <p className="readout">
                      {formData[0].data[1].value === 'Other'
                        ? 'Description:'
                        : 'Reason left program:'}{' '}
                      <b className="ml-3">{reason}</b>
                    </p>
                  </Col>
                </Row>
              )}
              <p>
                <br />
              </p>
              {/* // Academic Status */}
              {/* Tuition Status */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q3</b>
                    </Label>{' '}
                    Tuition status: <b className="ml-3">{formData[0].data[6].value}</b>
                  </p>
                </Col>
              </Row>
              <p>
                <br />
              </p>
              {/* // Tuition Status */}
              {/* Combined GPA */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q4</b>
                    </Label>{' '}
                    Combined GPA: <b className="ml-3">{formData[0].data[7].value}</b>
                  </p>
                </Col>
              </Row>
              <p>
                <br />
              </p>
              {/* // Combined GPA */}
              {/* Failed Courses */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q5</b>
                    </Label>{' '}
                    Did the student fail any courses?{' '}
                    <b className="ml-3">{formData[0].data[8].value}</b>
                  </p>
                </Col>
              </Row>
              {failedCourses.length > 0 && (
                <Row className="justify-content-center mt-4">
                  <Col className="text-center">
                    <Label>
                      <u>
                        <b>Failed Courses</b>
                      </u>
                    </Label>
                  </Col>
                  {failedCourses.map((course, idx) => (
                    <Col xs={12} key={`fc-${idx}`} className="mt-2 text-center">
                      <p>{course.FailedCourse}</p>
                      <Table hover>
                        <tbody>
                          <tr>
                            <th scope="row" className="text-right">
                              Year:
                            </th>
                            <td className="text-left">{course.FailedYear}</td>
                            <th scope="row" className="text-right">
                              Term:
                            </th>
                            <td className="text-left">{course.FailedTerm}</td>
                            <th scope="row" className="text-right">
                              Grade:
                            </th>
                            <td className="text-left">{course.FailedGrade}</td>
                          </tr>
                          <tr>
                            <th scope="row" className="text-right">
                              Retaken Year:
                            </th>
                            <td className="text-left">{course.RetakenYear}</td>
                            <th scope="row" className="text-right">
                              Retaken Term:
                            </th>
                            <td className="text-left">{course.RetakenTerm}</td>
                            <th scope="row" className="text-right">
                              Retaken Grade:
                            </th>
                            <td className="text-left">{course.RetakenGrade}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  ))}
                </Row>
              )}
              <p>
                <br />
              </p>
              {/* // Failed Courses */}
            </Widget>
            <Widget
              title={
                <h5>
                  {' '}
                  <i className="fa fa-align-left" /> Student Ratings{' '}
                </h5>
              }
            >
              {/* this alert only displays in the active form, not the readout  */}

              <Row className="mt-4">
                <Col>
                  <Alert color="primary">
                    <p>For the next set of questions, please use the following guidelines:</p>
                    <Row>
                      <Col xs={10}>
                        <Row>
                          <Col sm={2} className="text-center">
                            <strong>Rating</strong>
                          </Col>
                          <Col sm={3}>
                            <strong>Evaluation</strong>
                          </Col>
                          <Col sm={7}>
                            <strong>Academic Rating</strong>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={2} className="text-center">
                            <strong>1</strong>
                          </Col>
                          <Col sm={3}>Unacceptable</Col>
                          <Col sm={7}>GPA below 3</Col>
                        </Row>
                        <Row>
                          <Col sm={2} className="text-center">
                            <strong>2</strong>
                          </Col>
                          <Col sm={3}>Below expectations</Col>
                          <Col sm={7}>GPA below 3.5</Col>
                        </Row>
                        <Row>
                          <Col sm={2} className="text-center">
                            <strong>3</strong>
                          </Col>
                          <Col sm={3}>Meets expectations</Col>
                          <Col sm={7}>GPA equal to 3.5 - 3.69</Col>
                        </Row>
                        <Row>
                          <Col sm={2} className="text-center">
                            <strong>4</strong>
                          </Col>
                          <Col sm={3}>Exceeds expectations</Col>
                          <Col sm={7}>GPA equal to 3.7 - 3.89</Col>
                        </Row>
                        <Row>
                          <Col sm={2} className="text-center">
                            <strong>5</strong>
                          </Col>
                          <Col sm={3}>Outstanding</Col>
                          <Col sm={7}>GPA equal to / greater than 3.9</Col>
                        </Row>
                      </Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              <p>
                <br />
              </p>
              {/* Student Ratings - Q1 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q6</b>
                    </Label>{' '}
                    Suggested overall academic rating:{' '}
                    <b className="ml-3">
                      {formData[1].data[0].value === '' || formData[1].data[0].value === 0
                        ? formData[1].data[0].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[1].data[0].value}
                    </b>
                  </p>
                </Col>
              </Row>
              <p>
                <br />
              </p>
              {/* // Student Ratings - Q1 */}
              {/* Student Academic Ratings Comments */}
              <Row>
                <Col className="text-center">
                  <Label>
                    <u>
                      <b>Academic Rating Comments</b>
                    </u>
                  </Label>
                  <p className="readoutComments">{academicComments}</p>
                </Col>
              </Row>
              <p>
                <br />
              </p>
              {/* // Student Academic Ratings Comments */}
              {/* Student Ratings - Q2 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q7</b>
                    </Label>{' '}
                    Suggested overall research rating:{' '}
                    <b className="ml-3">
                      {formData[1].data[1].value === '' || formData[1].data[1].value === 0
                        ? formData[1].data[1].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[1].data[1].value}
                    </b>
                  </p>
                </Col>
              </Row>
              <p>
                <br />
              </p>
              {/* // Student Ratings - Q2 */}
              {currentProgram === 'Ph.D.' && (
                <div>
                  {/* Student Ratings - Q3 - Ph.D. Only */}
                  <Row>
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q8</b>
                        </Label>{' '}
                        Student&apos;s commitment to research lab:{' '}
                        <b className="ml-3">
                          {formData[1].data[2].value === '' || formData[1].data[2].value === 0
                            ? formData[1].data[2].value === ''
                              ? 'Unrated'
                              : 'N/A'
                            : formData[1].data[2].value}
                        </b>
                      </p>
                    </Col>
                  </Row>
                  <p>
                    <br />
                  </p>
                  {/* // Student Ratings - Q3 - Ph.D. Only */}
                  {/* Student Ratings - Q4 - Ph.D. Only */}
                  <Row>
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q9</b>
                        </Label>{' '}
                        Student&apos;s interest and motivation to conduct research:{' '}
                        <b className="ml-3">
                          {formData[1].data[3].value === '' || formData[1].data[3].value === 0
                            ? formData[1].data[3].value === ''
                              ? 'Unrated'
                              : 'N/A'
                            : formData[1].data[3].value}
                        </b>
                      </p>
                    </Col>
                  </Row>
                  <p>
                    <br />
                  </p>
                  {/* // Student Ratings - Q4 - Ph.D. Only */}
                  {/* Student Ratings - Q5 - Ph.D. Only */}
                  <Row>
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q10</b>
                        </Label>{' '}
                        Student engagement in activities that will increase higher research
                        productivity:{' '}
                        <b className="ml-3">
                          {formData[1].data[4].value === '' || formData[1].data[4].value === 0
                            ? formData[1].data[4].value === ''
                              ? 'Unrated'
                              : 'N/A'
                            : formData[1].data[4].value}
                        </b>
                      </p>
                    </Col>
                  </Row>
                  <p>
                    <br />
                  </p>
                  {/* // Student Ratings - Q5 - Ph.D. Only */}
                </div>
              )}
              {currentProgram === 'Psy.D.' && (
                <div>
                  <Row className="justify-content-center">
                    <Col sm={10} className="text-center">
                      <Label>
                        <b>Q8 through Q10 are Ph.D. only</b>
                      </Label>
                    </Col>
                  </Row>
                  <p>
                    <br />
                  </p>
                </div>
              )}
              {/* Student Ratings - Q6 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q11</b>
                    </Label>{' '}
                    Student's commitment to the degree model:{' '}
                    <b className="ml-3">
                      {formData[1].data[5].value === '' || formData[1].data[5].value === 0
                        ? formData[1].data[5].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[1].data[5].value}
                    </b>
                  </p>
                </Col>
              </Row>
              <p>
                <br />
              </p>
              {/* // Student Ratings - Q6 */}
              {/* Student Ratings Research Comments */}
              <Row>
                <Col className="text-center">
                  <Label>
                    <u>
                      <b>Research Rating Comments</b>
                    </u>
                  </Label>
                  <p className="readoutComments">{researchComments}</p>
                </Col>
              </Row>
              <p>
                <br />
              </p>
              {/* // Student Ratings Research Comments */}
              {/* Student Ratings - Q7 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q12</b>
                    </Label>{' '}
                    Suggested overall clinical rating:{' '}
                    <b className="ml-3">
                      {formData[1].data[6].value === '' || formData[1].data[6].value === 0
                        ? formData[1].data[6].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[1].data[6].value}
                    </b>
                  </p>
                </Col>
              </Row>
              <p>
                <br />
              </p>
              {/* // Student Ratings - Q7 */}
              {/* Student Ratings Clinical Comments */}
              <Row>
                <Col className="text-center">
                  <Label>
                    <u>
                      <b>Clinical Rating Comments</b>
                    </u>
                  </Label>
                  <p className="readoutComments">{clinicalComments}</p>
                </Col>
              </Row>
              <p>
                <br />
              </p>
              {/* // Student Ratings Clinical Comments */}
              {/* Student Ratings - Q8 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q13</b>
                    </Label>{' '}
                    Suggested overall professionalism rating:{' '}
                    <b className="ml-3">
                      {formData[1].data[7].value === '' || formData[1].data[7].value === 0
                        ? formData[1].data[7].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[1].data[7].value}
                    </b>
                  </p>
                </Col>
              </Row>
              <p>
                <br />
              </p>
              {/* // Student Ratings - Q8 */}
              {/* Student Ratings Professionalism Comments */}
              <Row>
                <Col className="text-center">
                  <Label>
                    <u>
                      <b>Professionalism Rating Comments</b>
                    </u>
                  </Label>
                  <p className="readoutComments">{professionalismComments}</p>
                </Col>
              </Row>
              <p>
                <br />
              </p>
              {/* // Student Ratings Professionalism Comments */}
              {renderQ14 && (
                <div>
                  {/* Program Extension Comments */}
                  <Row>
                    <Col className="text-center">
                      <Label>
                        <u>
                          <b>Reason for Program Extension Comments</b>
                        </u>
                      </Label>
                      <p className="readoutComments">{programExtensionComments}</p>
                    </Col>
                  </Row>
                  <p>
                    <br />
                  </p>
                  {/* // Program Extension Comments */}
                </div>
              )}
              {!renderQ14 && (
                <div>
                  <Row className="justify-content-center">
                    <Col sm={10} className="text-center">
                      <Label>
                        <b>Q14 Year 7+ Ph.D. and Year 6+ Psy.D. only</b>
                      </Label>
                    </Col>
                  </Row>
                  <p>
                    <br />
                  </p>
                </div>
              )}
              <FormGroup>
                <Form>
                  <FormGroup row className="submitBlock">
                    <Col className="text-center">
                      <Button
                        className="ml-xs"
                        id="readout-b-profile"
                        color="info"
                        tag={Link}
                        to="/dashboard"
                      >
                        Return to Dashboard
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { loading, currentSection, studentID, studentName } = this.state;
    if (!loading) {
      return (
        <div>
          <Row>
            <Col xs={{ size: 10, offset: 1 }}>
              <Row>
                <Col xs="12">
                  <div className="crumbs">
                    <Breadcrumb>
                      <BreadcrumbItem>
                        <Link to="/dashboard">Dashboard</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <Link to={`/student/${studentID}`}>{studentName}</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>Annual Student Evaluation</BreadcrumbItem>
                    </Breadcrumb>
                  </div>
                </Col>
              </Row>
              <h2 className="page-title">Annual Student Evaluation: {studentName}</h2>
              {currentSection === 0 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={0} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 0 && this.renderAcademicStanding()}
              {currentSection === 1 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={38} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 1 && this.renderStudentRatings()}
              {currentSection === 999 && this.renderFacultyReadout()}
            </Col>
          </Row>
        </div>
      );
    }
    return (
      <div>
        <Row>
          <Col xs={{ size: 10, offset: 1 }}>
            <h2 className="page-title">Annual Student Evaluation</h2>
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
          </Col>
        </Row>
      </div>
    );
  }
}

ASE.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(ASE);
